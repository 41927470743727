var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[(Object.keys(_vm.config).length && _vm.product.items.length)?_c('div',{staticClass:"product-container",style:(_vm.cssProps)},[_c('iconAndTitle',{attrs:{"iconUrl":_vm.config.widget_settings.logo ? _vm.config.widget_settings.logo : '',"title":_vm.config.widget_settings.title,"subTitle":_vm.config.widget_settings.sub_title}}),_c('div',{staticClass:"flex grid-gap-15 scroll-container"},[_c('div',{staticClass:"rec_left",class:!_vm.scrollView.left
            ? 'visibility_hidden'
            : _vm.config.widget_settings.scroll_arrows
            ? 'white_bg_arrow'
            : 'transparent_bg_left_arrow',attrs:{"id":"left-button"},on:{"click":_vm.swipeLeft}},[_c('img',{staticClass:"arrow left-arrow",attrs:{"alt":"arrow_left","src":require("../../../../src/assets/images/arrow.svg")}})]),_c('div',{ref:"content",staticClass:"flex grid-gap-15 rec_center",attrs:{"id":"content"}},_vm._l((_vm.product.items.slice(
            0,
            _vm.config.widget_settings.max_products
          )),function(n){return _c('div',{key:n,staticClass:"product",class:_vm.config.product_card_layout.style},[_c('fdk-product-card',{ref:"productCard",refInFor:true,scopedSlots:_vm._u([{key:"default",fn:function(productData){return [_c('fdk-accounts',{scopedSlots:_vm._u([{key:"default",fn:function(accountsData){return [(_vm.config.wishlist.active && _vm.isMounted)?_c('div',{staticClass:"wishlist-icon",style:([
                  _vm.config.discount.position == 'top-right' ||
                  _vm.config.discount.position == 'top-right-inverted' ||
                  _vm.config.discount.position.startsWith('bottom') ||
                  _vm.config.discount.position == 'top-right-horizontal'
                    ? { left: '12px' }
                    : { right: '12px' },
                ]),on:{"click":function($event){return _vm.wishit($event, n, productData, accountsData)}}},[(_vm.isMounted && _vm.follow[n.slug])?_c('div',[_c('span',{staticClass:"inline-svg"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"fill":_vm.config.wishlist.color,"d":"M12 4.248c-3.148-5.402-12-3.825-12 2.944 0 4.661 5.571 9.427 12 15.808 6.43-6.381 12-11.147 12-15.808 0-6.792-8.875-8.306-12-2.944z"}})])])]):_c('div',[_c('span',{staticClass:"inline-svg"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"fill":"#c4c4c4","d":"M12 4.248c-3.148-5.402-12-3.825-12 2.944 0 4.661 5.571 9.427 12 15.808 6.43-6.381 12-11.147 12-15.808 0-6.792-8.875-8.306-12-2.944z"}})])])])]):_vm._e()]}}],null,true)})]}}],null,true)}),_c('a',{staticClass:"redirect-a-tag",attrs:{"href":`/product/${n.slug}?utm_source=${_vm.utm.source}&utm_medium=${_vm.utm.medium}`,"target":"blank"}},[_c('imageAndOffer',{attrs:{"active":_vm.config.discount.active,"cardLayoutStyle":_vm.config.product_card_layout.style,"iconUrl":n.medias && n.medias.length ? n.medias[0].url : _vm.DEFAULT_IMG,"slug":n.slug,"productImageScale":_vm.config.product_image.scale,"discountPosition":_vm.config.discount.position,"discount":n.discount}}),(
                _vm.config.discount.active &&
                n.discount != '' &&
                _vm.config.discount.position.includes('inverted') &&
                _vm.config.discount.text_orientation.includes('horizontal')
              )?_c('labelInvertedHorizontal',{attrs:{"discountPosition":_vm.config.discount.position,"discount":n.discount}}):(
                _vm.config.discount.active &&
                n.discount != '' &&
                _vm.config.discount.position.includes('inverted') &&
                _vm.config.discount.text_orientation.includes('vertical')
              )?_c('labelInvertedVertical',{attrs:{"discountPosition":_vm.config.discount.position,"discount":n.discount}}):(
                _vm.config.discount.active &&
                n.discount != '' &&
                !(
                  _vm.config.product_card_layout.style == 'card-layout-1' &&
                  _vm.config.discount.position == 'bottom-left'
                )
              )?_c('labelHorizontal',{attrs:{"discountPosition":_vm.config.discount.position,"discount":n.discount}}):_vm._e(),_c('div',{staticClass:"content-box"},[(_vm.isMounted)?_c('div',{staticClass:"review"},[(_vm.config.reviews.show_stars)?_c('span',_vm._l((5),function(i){return _c('span',{key:i},[_c('span',{staticClass:"inline-svg"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"15","viewBox":"0 0 16 15"}},[_c('path',{attrs:{"d":"M8 0.5L9.79611 6.02786H15.6085L10.9062 9.44427L12.7023 14.9721L8 11.5557L3.29772 14.9721L5.09383 9.44427L0.391548 6.02786H6.20389L8 0.5Z","fill":_vm.getColor(n, i)}})])])])}),0):_vm._e(),(_vm.config.reviews.show_count)?_c('span',{staticClass:"review__count"},[_vm._v(" "+_vm._s(_vm.review_map[n.slug].reviews_count)+" Reviews")]):_vm._e()]):_vm._e(),(_vm.config.product_title.layout == 'layout1')?_c('div',{staticClass:"product-name"},[(_vm.config.product_title.active)?_c('span',{staticClass:"brand"},[_vm._v(_vm._s(n.brand.name))]):_vm._e(),(_vm.config.product_title.active)?_c('span',{staticClass:"divider"},[_vm._v("|")]):_vm._e(),_c('span',{staticClass:"name"},[_vm._v(_vm._s(n.name))])]):_vm._e(),(_vm.config.product_title.layout == 'layout2')?_c('div',{staticClass:"product-name second"},[(_vm.config.product_title.active)?_c('span',{staticClass:"brand"},[_vm._v(_vm._s(n.brand.name))]):_vm._e(),_c('br'),_c('span',{staticClass:"name"},[_vm._v(_vm._s(n.name))])]):_vm._e(),_c('priceBox',{attrs:{"price":n.price,"isPriceActive":_vm.config.price.active,"priceLayout":_vm.config.price.layout}}),_c('highlightsBox',{attrs:{"isHighlightsActive":_vm.config.product_highlights.active,"highlights":n.highlights,"highlightsCount":_vm.config.product_highlights.highlight_count}})],1)],1)],1)}),0),_c('div',{staticClass:"rec_right",class:!_vm.scrollView.right
            ? 'visibility_hidden'
            : _vm.config.widget_settings.scroll_arrows
            ? 'white_bg_arrow'
            : 'transparent_bg_right_arrow',attrs:{"id":"right-button"},on:{"click":_vm.swipeRight}},[_c('img',{staticClass:"arrow right-arrow",attrs:{"alt":"arrow_right","src":require("../../../../src/assets/images/arrow.svg")}})])])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }