<template>
    <UpSellAppComp id="up-sell"/>
</template>
  
  <script>
import UpSellAppComp from "./UpSellAppComp.vue";
  export default {
    name: 'by-up-sell', // why all components have same name?
    components: {
    UpSellAppComp
}
  }
  </script>