<template>
  <svg
    width="58"
    height="58"
    viewBox="0 0 58 58"
    :fill="selectedColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_516_69516)">
      <rect
        x="5"
        y="5"
        width="40"
        height="40"
        rx="20"
        fill="white"
      />
      <path
        d="M28.2889 18C31.1067 18 33 20.682 33 23.184C33 28.251 25.1422 32.4 25 32.4C24.8578 32.4 17 28.251 17 23.184C17 20.682 18.8933 18 21.7111 18C23.3289 18 24.3867 19.28 25 20C25.6133 19.28 26.6711 18 28.2889 18Z"
        stroke="#365660"
        stroke-width="1.6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_516_69516"
        x="0"
        y="0"
        width="58"
        height="58"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset
          dx="4"
          dy="4"
        />
        <feGaussianBlur stdDeviation="4.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.501961 0 0 0 0 0.607843 0 0 0 0 0.639216 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_516_69516"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_516_69516"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "WishListHeartIcon",
  props: {
    selectedColor: {
      type: String,
      default: "#ffffff",
      required: false,
    },
  },
  mounted() {
    console.log("SelectedColor = ", this.selectedColor);
  },
};
</script>

<style></style>
