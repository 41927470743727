<template>
    <CrossSellComp id="cross-sell"/>
  </template>
  
  <script>
  import CrossSellComp from "./CrossSellComp.vue";
  export default {
    name: 'by-cross-sell', // why all components have same name?
    components: {
    CrossSellComp
}
  }
  </script>