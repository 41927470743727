<template>
  <div
    :style="cssProps"
    v-if="Object.keys(config).length && product.items.length"
  >
    <div v-if="product?.items?.length <= 0"></div>
    <div v-else style="margin-top: 2rem">
      <iconAndTitle
        :iconUrl="
          config.widget_settings.logo ? config.widget_settings.logo : ''
        "
        :title="config.widget_settings.title"
        :subTitle="config.widget_settings.sub_title"
      />
      <div v-if="showLoader">
        <!-- Your loader component or HTML goes here -->
        <customLoader />
        <!-- Assuming you have a loader component named LoaderComponent -->
      </div>
      <div v-else>
        <div v-for="(element, index) in product?.items" :key="index">
          <CartItem
            :configProps="config"
            :elementProps="element"
            :indexProps="index"
            :followProps="follow"
            :availableSizeProps="availableSize"
            :availableQuantityProps="availableQuantity"
            :selectedSizeProps="selectedSize"
            :selectedQuantityProps="selectedQuantity"
            @testFunction="testFunction"
            @addItemToCart="addItemToCart"
            @wishit="wishit"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Utility } from "fdk-client-javascript/common";
import CartItem from "./CartItem.vue";
import customLoader from "../../../../src/components/customLoader.vue";

import iconAndTitle from "../../../../src/components/iconAndTitle.vue";
export default {
  name: "UpSellProducts",
  components: {
    iconAndTitle,
    CartItem,
    // loader,
    customLoader,
  },
  data() {
    return {
      config: Object,
      product: { items: [] },
      isMounted: false,
      follow: {},
      selectedQuantity: [],
      availableQuantity: [],
      selectedSize: [],
      availableSize: [],
      isWishListed: false,
      pageDetail: undefined,
      recomm_slug: undefined,
      showLoader: false,
    };
  },
  mounted() {
    let url = window.location.href;
    this.pageDetail = Utility.convertUrlToAction(url);
    this.recomm_slug = this.id;

    console.log(this.pageDetail);
    this.getData(this.recomm_slug, this.pageDetail);
    this.isMounted = true;
    window.FPI.event.on("wishlist.add", this.wishAdd);
    window.FPI.event.on("wishlist.remove", this.wishRemove);
  },
  methods: {
    testFunction() {
      console.log("OK!");
    },
    onWishList() {
      this.isWishListed = !this.isWishListed;
    },
    getData(recomm_slug, pageDetail) {
      var url = window.location.origin;
      axios
        .get(
          `${url}/ext/recalpha/api/platform/v1.0/recommendations/${recomm_slug}/pages/${pageDetail.page.type}/config?only-active=true`
        )
        .then((response) => {
          this.config = response.data;
          console.log(`data fetched successfully  ${response.status}`);

          this.getProduct(recomm_slug, pageDetail);
        })
        .catch((error) => {
          console.error("Failed to Fetch Config ::: ", error);
        });
    },
    getProduct(recomm_slug, pageDetail) {
      var url = window.location.origin;
      var apiUrl = `${url}/ext/recalpha/api/application/v1.0/recommend/${recomm_slug}`;
      var params = {};
      var cartItemList = [];
      if (pageDetail.page.params && pageDetail.page.params.slug) {
        params.item_slug = pageDetail.page.params.slug[0];
      }
      if (pageDetail?.page?.type === "cart") {
        const cartItem = window?.FPI?.state?.cart?._data?.all_cart_data?.items;
        params.item_slug = cartItem?.[0]?.product?.slug;
        for (let i = 0; i < cartItem?.length; i++) {
          cartItemList.push(cartItem?.[i]?.product?.slug);
        }
      }

      console.log(`cross sell slug sending in body parameter`);
      console.log(cartItemList);

      axios
        .post(apiUrl, {
          item_slug: cartItemList,
        })
        .then((response) => {
          this.product = response.data;
          var f = {};
          var id_to_slug = {}; // needed bcoz FPI wishlist event uses slug where as  state.products.wishlistIds.subscribe use ids
          var keymap = this.config.reviews.keymap; //review keymap
          var r = {};
          let availableSize = this.availableSize;
          let selectedSize = this.selectedSize;
          let availableQuantity = this.availableQuantity;
          let selectedQuantity = this.selectedQuantity;
          this.product.items.forEach(function (item) {
            //wishlist map
            f[item.slug] = false;
            id_to_slug[item.uid] = item.slug; //using id helps with FPI events

            //review data map
            var rmap = {
              ratings_total: 0,
              ratings_count: 0,
              reviews_count: 0,
            };
            item._custom_meta.forEach(function (meta_item) {
              if (keymap.ratings_total == meta_item.key) {
                rmap.ratings_total = parseInt(meta_item.value);
              } else if (keymap.ratings_count == meta_item.key) {
                rmap.ratings_count = parseInt(meta_item.value);
              } else if (keymap.reviews_count == meta_item.key) {
                rmap.reviews_count = parseInt(meta_item.value);
              }
            });

            rmap.rating = parseInt(rmap.ratings_total / rmap.ratings_count);
            r[item.slug] = rmap;
            if (item?.sizes?.length > 0) {
              availableSize.push(item?.sizes);
              selectedSize.push(item?.sizes?.[0]);
            }

            if (item?.net_quantity?.max > 0) {
              let x = [];
              for (let i = 1; i <= item?.net_quantity?.max; i++) {
                x.push(i);
              }
              availableQuantity.push(x);
              selectedQuantity.push(x?.[0]);
            } else {
              let x = [];
              x.push(1);
              availableQuantity.push(x);
              selectedQuantity.push(x?.[0]);
            }
          });
          this.availableSize = availableSize;
          this.selectedSize = selectedSize;
          this.selectedQuantity = selectedQuantity;
          this.availableQuantity = availableQuantity;
          if (this.availableSize && this.availableSize?.length > 0) {
            for (let i = 0; i < this.availableSize?.length; i++) {
              this.availableSize[i] = this.availableSize[i].map(
                (element, index) => {
                  return { id: index, text: element, value: element };
                }
              );
            }
          }

          if (this.availableQuantity && this.availableQuantity?.length > 0) {
            for (let i = 0; i < this.availableQuantity?.length; i++) {
              this.availableQuantity[i] = this.availableQuantity[i].map(
                (element, index) => {
                  return { id: index, text: element, value: element };
                }
              );
            }
          }

          //Adds already wishlisted items to map
          window.FPI.state.products.wishlistIds.subscribe((items) => {
            items.forEach((item) => {
              if (id_to_slug[item] !== undefined) {
                f[id_to_slug[item]] = true;
              }
            });
          });
          this.follow = f;
          this.review_map = r;
          console.log("Available Size = ", this.availableSize);
          console.log("Selected Size = ", this.selectedSize);
          console.log("Available Quantity = ", this.availableQuantity);
          console.log("Selected Quantity = ", this.selectedQuantity);
        })
        .catch((error) => {
          console.error("Failed to Fetch Products ::: ", error);
        });
    },
    addItemToCartNew(message) {
      console.log("This is message = ", message);
    },
    async addItemToCart(Obj) {
      let event = Obj?.event;
      let citem = Obj?.element;
      let cartData = Obj?.cartData;
      let productData = Obj?.productData;
      let accountsData = Obj?.accountsData;
      let elementIndex = Obj?.index;

      this.showLoader = true;

      console.log("Event = ", event);
      console.log("ProductData = ", productData);
      if (accountsData.is_logged_in) {
        // Toggle the buttonClicked property to apply the effect

        try {
          const fetchCartData = await cartData?.getCart();
          console.log("FetchCartData Res = ", fetchCartData);
          const addProductToCart = await cartData?.addToCart({
            items: [
              {
                item_id: citem?.uid,
                item_size: this.selectedSize[elementIndex],
                quantity: parseInt(this.selectedQuantity[elementIndex]),
              },
            ],
            buy_now: false,
          });
          console.log("AddProductToCart Res = ", addProductToCart);
          console.log(this.pageDetail);
          console.log(
            `recommend slug : @ ${this.recomm_slug} page details @ ${this.pageDetail}`
          );
          this.showLoader = false;

          this.selectedQuantity = [];
          this.availableQuantity = [];
          this.selectedSize = [];
          this.availableSize = [];
          this.getData(this.recomm_slug, this.pageDetail);
          console.log(`show loader after data fetch ${this.showLoader}`);
        } catch (error) {
          console.error("Failed to add product to cart ::: ", error);
          this.showLoader = false;
        }
      } else {
        this.showLoader = false;
        accountsData.openLogin();
      }
    },
    removeProduct(index) {
      this.products.splice(index, 1);
    },
    wishit(Obj) {
      console.log("Message wishit = ", Obj);
      let event = Obj?.event;
      let citem = Obj?.element;
      let productData = Obj?.productData;
      let accountsData = Obj?.accountsData;
      console.log("Follow = ", this.follow);
      if (accountsData.is_logged_in) {
        citem.follow = this.follow[citem.slug];
        productData.updateWishList(event, citem);
        this.onWishList();
      } else {
        accountsData.openLogin();
      }
    },
    wishAdd(data) {
      this.follow[data.slug] = true;
    },
    wishRemove(data) {
      this.follow[data.slug] = false;
    },
  },
  props: {
    id: String,
  },
  computed: {
    cssProps() {
      return {
        "--wishiconColor": this.config.wishlist.color,
        "--discountColor": this.config.discount.text_color,
        "--imageHeight": this.config.product_image.height + "px",
        "--discountSize": this.config.discount.text_size + "px",
        "--discountbgColor": this.config.discount.background_color,
        "--namebrandColor": this.config.product_title.text_color,
        "--nameproductColor": this.config.product_title.secondary_text_color,
        "--namebrandSize": this.config.product_title.font_size + "px",
        "--nameWidth": this.config.product_title.text_limit + "ch",
        "--brandFontWeight": this.config.product_title.highlight_brand_name
          ? 600
          : 400,
        "--breakWhiteSpace": this.config.product_title.multiline_product_name
          ? "nowrap"
          : "break-spaces",
        "--overflowVisible": this.config.product_title.multiline_product_name
          ? "hidden"
          : "visible",
        "--primaryPriceColor": this.config.price.text_color,
        "--secondaryPriceColor": this.config.price.secondary_text_color,
        "--pricefontSize": this.config.price.text_size + "px",
        "--addtocartFont": this.config.cart_button.font_size + "px",
        "--addtocartlabelColor": this.config.cart_button.label_color,
        "--addtocartbgColor": this.config.cart_button.background_color,
        "--titleColor": this.config.widget_settings.title_color,
        "--subtitleColor": this.config.widget_settings.subtitle_color,
        "--cardBorderRadius":
          this.config.product_card_style.border_radius + "px",
        "--cardBackgroundColor":
          this.config.product_card_style.background_color,
        "--addToCartBtnBgColor":
          this.config.product_card_style.add_to_cart_btn_bgColor,
        "--cardBorderColor":
          this.config.new_config.product_card_style.border_colour,
        "--cardPadding": this.config.product_card_style.padding + "px",
        "--highlightfontSize": this.config.product_highlights.font_size + "px",
        "--highlightMarginTop":
          this.config.product_highlights.margin_top + "px",
        "--highlightBorderBottom": this.config.product_highlights.border_bottom
          ? "1px"
          : "0px",
        "--highlightColor": this.config.product_highlights.font_color,
        "--cardBoxShadow": this.config.product_card_style.box_shadow
          ? "rgba(99, 99, 99, 0.5) 0px 2px 8px 0px"
          : "none",
        "--leftArrowVisibility":
          this.config.widget_settings.max_products > 2 ? "inherit" : "hidden",
        "--rightArrowVisibility":
          this.config.widget_settings.max_products > 2 ? "inherit" : "hidden",
        "--titleAlignment": this.config.new_config.widget_settings
          .title_alignment
          ? this.config.new_config.widget_settings.title_alignment
          : "left",
        "--subtitleAlignment": this.config.new_config.widget_settings
          .sub_title_alignment
          ? this.config.new_config.widget_settings.sub_title_alignment
          : "left",
        "--titleFontSize": this.config.new_config.widget_settings
          .title_font_settings.font_size
          ? `${this.config.new_config.widget_settings.title_font_settings.font_size}px`
          : `12px`,
        "--subtitleFontSize": this.config.new_config.widget_settings
          .sub_title_font_settings.font_size
          ? `${this.config.new_config.widget_settings.sub_title_font_settings.font_size}px`
          : `12px`,
        "--titleFontWeight": this.config.new_config.widget_settings
          .title_font_settings.font_weight
          ? `${this.config.new_config.widget_settings.title_font_settings.font_weight}`
          : 600,
        "--subtitleFontWeight": this.config.new_config.widget_settings
          .sub_title_font_settings.font_weight
          ? `${this.config.new_config.widget_settings.sub_title_font_settings.font_weight}`
          : 500,
        "--reviewFontSize": this.config.new_config.reviews.review_font_size
          ? `${this.config.new_config.reviews.review_font_size}px`
          : `12px`,
        "--reviewFontColor": this.config.new_config.reviews.review_font_colour,
      };
    },
  },
};
</script>

<style lang="less" scoped>
@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300;0,400;0,500;1,300&family=Red+Hat+Text:wght@400;500&display=swap");
@import "../less/CartItem.less";
</style>
