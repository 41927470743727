var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.price && _vm.isPriceActive && _vm.priceLayout == 'layout1')?_c('div',{staticClass:"price"},[(_vm.price.effective.min == _vm.price.effective.max)?_c('span',{staticClass:"selling-price"},[_vm._v(_vm._s(_vm.price.effective.currency_symbol)+_vm._s(_vm.numberWithCommas(_vm.price.effective.max))+" ")]):_vm._e(),(_vm.price.effective.min != _vm.price.effective.max)?_c('span',{staticClass:"selling-price"},[_vm._v(_vm._s(_vm.price.effective.currency_symbol)+_vm._s(_vm.numberWithCommas(_vm.price.effective.min))+" - "+_vm._s(_vm.price.effective.currency_symbol)+_vm._s(_vm.numberWithCommas(_vm.price.effective.max))+" ")]):_vm._e(),(
        _vm.price.marked != _vm.price.effective &&
        _vm.price.marked.min == _vm.price.marked.max &&
        _vm.price.marked.max != _vm.price.effective.max &&
        _vm.price.marked.max != _vm.price.effective.min
      )?_c('span',{staticClass:"cut-price"},[_vm._v(_vm._s(_vm.price.marked.currency_symbol)+_vm._s(_vm.numberWithCommas(_vm.price.marked.max)))]):_vm._e(),(
        _vm.price.marked != _vm.price.effective &&
        _vm.price.marked.min != _vm.price.marked.max &&
        (_vm.price.marked.max != _vm.price.effective.max ||
          _vm.price.marked.min != _vm.price.effective.min)
      )?_c('span',{staticClass:"cut-price"},[_vm._v(_vm._s(_vm.price.marked.currency_symbol)+_vm._s(_vm.numberWithCommas(_vm.price.marked.min))+" - "+_vm._s(_vm.price.marked.currency_symbol)+_vm._s(_vm.numberWithCommas(_vm.price.marked.max)))]):_vm._e()]):_vm._e(),(_vm.price && _vm.isPriceActive && _vm.priceLayout == 'layout2')?_c('div',{staticClass:"price"},[(
        _vm.price.marked != _vm.price.effective &&
        _vm.price.marked.min == _vm.price.marked.max
      )?_c('span',{staticClass:"cut-price"},[_vm._v(_vm._s(_vm.price.marked.currency_symbol)+_vm._s(_vm.numberWithCommas(_vm.price.marked.max)))]):_vm._e(),(
        _vm.price.marked != _vm.price.effective &&
        _vm.price.marked.min != _vm.price.marked.max
      )?_c('span',{staticClass:"cut-price"},[_vm._v(_vm._s(_vm.price.marked.currency_symbol)+_vm._s(_vm.numberWithCommas(_vm.price.marked.min))+" - "+_vm._s(_vm.price.marked.currency_symbol)+_vm._s(_vm.numberWithCommas(_vm.price.marked.max)))]):_vm._e(),(_vm.price.marked.min == _vm.price.marked.max)?_c('span',{staticClass:"selling-price"},[_vm._v(" "+_vm._s(_vm.price.effective.currency_symbol)+_vm._s(_vm.numberWithCommas(_vm.price.effective.max))+" ")]):_vm._e(),(_vm.price.marked.min != _vm.price.marked.max)?_c('span',{staticClass:"selling-price"},[_vm._v(" "+_vm._s(_vm.price.effective.currency_symbol)+_vm._s(_vm.numberWithCommas(_vm.price.effective.min))+" - "+_vm._s(_vm.price.effective.currency_symbol)+_vm._s(_vm.numberWithCommas(_vm.price.effective.max)))]):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }