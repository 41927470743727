<template>
  <div>
    <fdk-product-card v-slot="productData" ref="productCard">
      <fdk-accounts v-slot="accountsData">
        <div class="container">
          <div class="item-container">
            <div class="item-image-container">
              <img class="item-image" :src="element?.medias?.[0]?.url" />
            </div>
            <div class="item-description">
              <div class="product-title">
                {{ capitalizeFirstLetter(element?.brand?.name) || "Sample Brand Name" }}
              </div>
              <div class="product-name">{{ element?.name }}</div>
              <div class="seller-info">
                Sold By:
                {{
                  element?.attributes?.["marketer-name"] || "Sample Seller Name"
                }}
              </div>

              <div class="add-to-cart-container">
                <div class="size-button-container">
                  <div class="custom-dropdown" v-if="availableSize?.length > 0">
                    <label for="size" class="custom-dropdown-title"
                      >Size:
                    </label>
                    <select
                      id="selected-size"
                      class="custom-dropdown-select"
                      v-model="selectedSize[index]"
                    >
                      <option
                        v-for="element in availableSize?.[index]"
                        :value="element?.value"
                        :key="element?.id"
                      >
                        {{ element?.text }}
                      </option>
                    </select>
                  </div>
                  <div class="custom-dropdown">
                    <label for="size" class="custom-dropdown-title"
                      >Qty:
                    </label>
                    <select
                      id="selected-size"
                      class="custom-dropdown-select"
                      v-model="selectedQuantity[index]"
                    >
                      <option
                        v-for="element in availableQuantity?.[index]"
                        :value="element?.value"
                        :key="element?.id"
                      >
                        {{ element?.text }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="cart-button-container">
                  <div
                    @click="wishit($event, element, productData, accountsData)"
                    class="wish-list-icon-container"
                    v-if="follow[element?.slug]"
                  >
                    <WishListHeartIcon :selectedColor="config.wishlist.color" />
                  </div>
                  <div
                    @click="wishit($event, element, productData, accountsData)"
                    class="wish-list-icon-container"
                    v-else
                  >
                    <WishListHeartIcon selectedColor="#ffffff" />
                  </div>
                  <fdk-cart v-slot="cartData" ref="cart">
                    <NitrozenButton
                      v-stroke-btn
                      v-ripple
                      theme="secondary"
                      class="add-to-cart-button"
                      size="small"
                      @click="
                        addItemToCart(
                          $event,
                          element,
                          cartData,
                          productData,
                          accountsData,
                          index
                        )
                      "
                      >Add to Cart</NitrozenButton
                    >
                  </fdk-cart>
                </div>
              </div>
              <div
                class="price-container"
                v-if="
                  config?.price?.active && config?.price?.layout === 'layout1'
                "
              >
                <div class="price-sub-container">
                  <div
                    class="discounted-price"
                    v-show="element?.discount && element?.discount !== ''"
                  >
                    {{
                      `${element?.price?.marked?.currency_symbol}${element?.price?.marked?.max}`
                    }}
                  </div>
                  <div class="actual-price">
                    {{
                      `${element?.price?.effective?.currency_symbol}${element?.price?.effective?.max}`
                    }}
                  </div>
                </div>
                <div
                  v-show="
                    config?.discount?.active &&
                    element?.discount &&
                    element?.discount !== ''
                  "
                  class="offer-applied-container"
                >
                  <div class="offer-applied-text">
                    {{ element?.discount }}
                  </div>
                  <ExclamationMarkIconSvg
                    alt="exclamation-mark-icon"
                    class="exclamation-mark-icon"
                    :color="config.discount.text_color"
                  />
                </div>
              </div>
              <div
                class="price-container"
                v-if="
                  config?.price?.active && config.price.layout === 'layout2'
                "
              >
                <div class="price-sub-container">
                  <div class="actual-price">
                    {{
                      `${element?.price?.effective?.currency_symbol}${element?.price?.effective?.max}`
                    }}
                  </div>
                  <div
                    class="discounted-price"
                    v-show="element?.discount && element?.discount !== ''"
                  >
                    {{
                      `${element?.price?.marked?.currency_symbol}${element?.price?.marked?.max}`
                    }}
                  </div>
                </div>
                <div
                  v-show="element?.discount && element?.discount !== ''"
                  class="offer-applied-container"
                >
                  <div class="offer-applied-text">
                    {{ element?.discount }}
                  </div>
                  <img
                    alt="exclamation-mark-icon"
                    class="exclamation-mark-icon"
                    src="../../../../src/assets/images/ExclamationMarkIcon.png"
                  />
                </div>
              </div>
              <div
                class="expected-delievery-container"
                v-show="config?.product_highlights?.active"
              >
                <div class="expected-delievery-title">Expected Delivery:</div>
                <div class="expected-delievery-text">----</div>
              </div>
              <div class="add-to-cart-container-mobile">
                <div class="size-button-container">
                  <div class="custom-dropdown" v-if="availableSize?.length > 0">
                    <label for="size" class="custom-dropdown-title"
                      >Size:
                    </label>
                    <select
                      id="selected-size"
                      class="custom-dropdown-select"
                      v-model="selectedSize[index]"
                    >
                      <option
                        v-for="element in availableSize?.[index]"
                        :value="element?.value"
                        :key="element?.id"
                      >
                        {{ element?.text }}
                      </option>
                    </select>
                  </div>
                  <div class="custom-dropdown">
                    <label for="size" class="custom-dropdown-title"
                      >Qty:
                    </label>
                    <select
                      id="selected-size"
                      class="custom-dropdown-select"
                      v-model="selectedQuantity[index]"
                    >
                      <option
                        v-for="element in availableQuantity?.[index]"
                        :value="element?.value"
                        :key="element?.id"
                      >
                        {{ element?.text }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="cart-button-container">
                  <div
                    @click="wishit($event, element, productData, accountsData)"
                    class="wish-list-icon-container"
                    v-if="follow[element?.slug]"
                  >
                    <WishListHeartIcon :selectedColor="config.wishlist.color" />
                  </div>
                  <div
                    @click="wishit($event, element, productData, accountsData)"
                    class="wish-list-icon-container"
                    v-else
                  >
                    <WishListHeartIcon selectedColor="#ffffff" />
                  </div>
                  <fdk-cart v-slot="cartData" ref="cart">
                    <NitrozenButton
                      v-stroke-btn
                      v-ripple
                      class="add-to-cart-button"
                      theme="secondary"
                      size="small"
                      @click="
                        addItemToCart(
                          $event,
                          element,
                          cartData,
                          productData,
                          accountsData,
                          index
                        )
                      "
                      >Add to Cart</NitrozenButton
                    >
                  </fdk-cart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </fdk-accounts>
    </fdk-product-card>
  </div>
</template>

<script>
import WishListHeartIcon from "./WishListHeartIcon.vue";
import { NitrozenButton } from "@gofynd/nitrozen-vue";
import ExclamationMarkIconSvg from "../../../../src/components/ExclamationMarkIconSvg.vue";
export default {
  name: "CartItemNew",
  props: {
    elementProps: Object,
    indexProps: Number,
    configProps: Object,
    followProps: Object,
    availableSizeProps: Object,
    availableQuantityProps: Object,
    selectedSizeProps: Object,
    selectedQuantityProps: Object,
  },
  components: {
    WishListHeartIcon,
    NitrozenButton,
    ExclamationMarkIconSvg,
  },
  data() {
    return {
      element: {},
      index: 0,
      config: {},
      follow: {},
      availableSize: [],
      availableQuantity: [],
      selectedSize: [],
      selectedQuantity: [],
    };
  },
  methods: {
    capitalizeFirstLetter(string) {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
    testFunction() {
      this.$emit("testFunction");
    },
    addItemToCart(event, element, cartData, productData, accountsData, index) {
      this.$emit("addItemToCart", {
        event,
        element,
        cartData,
        productData,
        accountsData,
        index,
      });
      this.removeItem(index);
    },
    removeItem(index) {
      this.$emit("removeItem", index);
    },
    wishit(event, element, productData, accountsData) {
      this.$emit("wishit", {
        event,
        element,
        productData,
        accountsData,
      });
    },
  },
  mounted() {
    this.element = this.elementProps;
    this.index = this.indexProps;
    this.config = this.configProps;
    this.follow = this.followProps;
    this.availableSize = this.availableSizeProps;
    this.availableQuantity = this.availableQuantityProps;
    this.selectedSize = this.selectedSizeProps;
    this.selectedQuantity = this.selectedQuantityProps;
  },
};
</script>

<style lang="less" scoped>
@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300;0,400;0,500;1,300&family=Red+Hat+Text:wght@400;500&display=swap");
@import "../less/CartItem.less";
</style>
