import ByBrand from "./components/ByBrand.vue";

window.FPI.extension.register("#ext-product-recommendation_brand", {
  mounted(element) {
    window.FPI.extension.mountApp({
      element,
      component: ByBrand,
    });
  },
});

import ByWishlist from "./components/ByWishlist.vue";

window.FPI.extension.register(
  "#ext-product-recommendation_wishlisted-products",
  {
    mounted(element) {
      window.FPI.extension.mountApp({
        element,
        component: ByWishlist,
      });
    },
  }
);

import ByRecent from "./components/ByRecent.vue";

window.FPI.extension.register("#ext-product-recommendation_recently-launched", {
  mounted(element) {
    window.FPI.extension.mountApp({
      element,
      component: ByRecent,
    });
  },
});

import BySpecification from "./components/BySpecification.vue";

window.FPI.extension.register("#ext-product-recommendation_specification", {
  mounted(element) {
    window.FPI.extension.mountApp({
      element,
      component: BySpecification,
    });
  },
});

import ByVendor from "./components/ByVendor.vue";
window.FPI.extension.register("#ext-product-recommendation_vendor", {
  mounted(element) {
    window.FPI.extension.mountApp({
      element,
      component: ByVendor,
    });
  },
});

import ByPrice from "./components/ByPrice.vue";
window.FPI.extension.register("#ext-product-recommendation_price", {
  mounted(element) {
    window.FPI.extension.mountApp({
      element,
      component: ByPrice,
    });
  },
});

import ByCategory from "./components/ByCategory.vue";
window.FPI.extension.register("#ext-product-recommendation_category", {
  mounted(element) {
    window.FPI.extension.mountApp({
      element,
      component: ByCategory,
    });
  },
});

import ByMostCompared from "./components/ByMostCompared.vue";
window.FPI.extension.register("#ext-product-recommendation_most-compared", {
  mounted(element) {
    window.FPI.extension.mountApp({
      element,
      component: ByMostCompared,
    });
  },
});

import BySimilarProducts from "./components/BySimilarProducts";
window.FPI.extension.register("#ext-product-recommendation_similar-products", {
  mounted(element) {
    window.FPI.extension.mountApp({
      element,
      component: BySimilarProducts,
    });
  },
});

import ByBoughtTogether from "./components/ByBoughtTogether";
window.FPI.extension.register("#ext-product-recommendation_bought_together", {
  mounted(element) {
    window.FPI.extension.mountApp({
      element,
      component: ByBoughtTogether,
    });
  },
});

import ByUpSell from "./components/ByUpSell";
window.FPI.extension.register("#ext-product-recommendation_up-sell", {
  mounted(element) {
    window.FPI.extension.mountApp({
      element,
      component: ByUpSell,
    });
  },
});

import ByCrossSell from "./components/ByCrossSell";
window.FPI.extension.register("#ext-product-recommendation_cross-sell", {
  mounted(element) {
    window.FPI.extension.mountApp({
      element,
      component: ByCrossSell,
    });
  },
});

function onload() {
  console.log("onload function is called from main.js file");
}

window.document.onload = onload();
