<template>
    <App id="vendor"/>
</template>

<script>
import App from './App.vue'
export default {
    name: 'by-brand',
    components: {
        App
    }
}
</script>