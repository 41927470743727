<template>
  <div class="container">
    <div
      class="product-container"
      v-if="Object.keys(config).length && product.items.length"
      :style="cssProps"
    >
      <iconAndTitle
        :iconUrl="
          config.widget_settings.logo ? config.widget_settings.logo : ''
        "
        :title="config.widget_settings.title"
        :subTitle="config.widget_settings.sub_title"
      />
      <div class="flex grid-gap-15 scroll-container">
        <div
          class="rec_left"
          :class="
            !scrollView.left
              ? 'visibility_hidden'
              : config.widget_settings.scroll_arrows
              ? 'white_bg_arrow'
              : 'transparent_bg_left_arrow'
          "
          id="left-button"
          @click="swipeLeft"
        >
          <img
            alt="arrow_left"
            class="arrow left-arrow"
            src="../../../../src/assets/images/arrow.svg"
          />
        </div>

        <div class="flex grid-gap-15 rec_center" id="content" ref="content">
          <div
            class="product"
            :class="config.product_card_layout.style"
            v-for="n in product.items.slice(
              0,
              config.widget_settings.max_products
            )"
            :key="n"
          >
            <fdk-product-card v-slot="productData" ref="productCard">
              <fdk-accounts v-slot="accountsData">
                <div
                  class="wishlist-icon"
                  v-if="config.wishlist.active && isMounted"
                  :style="[
                    config.discount.position == 'top-right' ||
                    config.discount.position == 'top-right-inverted' ||
                    config.discount.position.startsWith('bottom') ||
                    config.discount.position == 'top-right-horizontal'
                      ? { left: '12px' }
                      : { right: '12px' },
                  ]"
                  @click="wishit($event, n, productData, accountsData)"
                >
                  <div v-if="isMounted && follow[n.slug]">
                    <span class="inline-svg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          :fill="config.wishlist.color"
                          d="M12 4.248c-3.148-5.402-12-3.825-12 2.944 0 4.661 5.571 9.427 12 15.808 6.43-6.381 12-11.147 12-15.808 0-6.792-8.875-8.306-12-2.944z"
                        ></path>
                      </svg>
                    </span>
                  </div>
                  <div v-else>
                    <span class="inline-svg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#c4c4c4"
                          d="M12 4.248c-3.148-5.402-12-3.825-12 2.944 0 4.661 5.571 9.427 12 15.808 6.43-6.381 12-11.147 12-15.808 0-6.792-8.875-8.306-12-2.944z"
                        ></path>
                      </svg>
                    </span>
                  </div>
                </div>
              </fdk-accounts>
            </fdk-product-card>
            <a
              class="redirect-a-tag"
              :href="`/product/${n.slug}?utm_source=${utm.source}&utm_medium=${utm.medium}`"
              target="blank"
            >
              <imageAndOffer
                :active="config.discount.active"
                :cardLayoutStyle="config.product_card_layout.style"
                :iconUrl="
                  n.medias && n.medias.length ? n.medias[0].url : DEFAULT_IMG
                "
                :slug="n.slug"
                :productImageScale="config.product_image.scale"
                :discountPosition="config.discount.position"
                :discount="n.discount"
              />
              <labelInvertedHorizontal
                v-if="
                  config.discount.active &&
                  n.discount != '' &&
                  config.discount.position.includes('inverted') &&
                  config.discount.text_orientation.includes('horizontal')
                "
                :discountPosition="config.discount.position"
                :discount="n.discount"
              />
              <labelInvertedVertical
                v-else-if="
                  config.discount.active &&
                  n.discount != '' &&
                  config.discount.position.includes('inverted') &&
                  config.discount.text_orientation.includes('vertical')
                "
                :discountPosition="config.discount.position"
                :discount="n.discount"
              />
              <labelHorizontal
                v-else-if="
                  config.discount.active &&
                  n.discount != '' &&
                  !(
                    config.product_card_layout.style == 'card-layout-1' &&
                    config.discount.position == 'bottom-left'
                  )
                "
                :discountPosition="config.discount.position"
                :discount="n.discount"
              />
              <div class="content-box">
                <div v-if="isMounted" class="review">
                  <span v-if="config.reviews.show_stars">
                    <span v-for="i in 5" :key="i">
                      <span class="inline-svg"
                        ><svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="15"
                          viewBox="0 0 16 15"
                        >
                          <path
                            d="M8 0.5L9.79611 6.02786H15.6085L10.9062 9.44427L12.7023 14.9721L8 11.5557L3.29772 14.9721L5.09383 9.44427L0.391548 6.02786H6.20389L8 0.5Z"
                            :fill="getColor(n, i)"
                          ></path></svg
                      ></span>
                    </span>
                  </span>
                  <span v-if="config.reviews.show_count" class="review__count">
                    {{ review_map[n.slug].reviews_count }} Reviews</span
                  >
                </div>
                <div
                  class="product-name"
                  v-if="config.product_title.layout == 'layout1'"
                >
                  <span v-if="config.product_title.active" class="brand">{{
                    n.brand.name
                  }}</span>
                  <span v-if="config.product_title.active" class="divider"
                    >|</span
                  >
                  <span class="name">{{ n.name }}</span>
                </div>
                <div
                  class="product-name second"
                  v-if="config.product_title.layout == 'layout2'"
                >
                  <span v-if="config.product_title.active" class="brand">{{
                    n.brand.name
                  }}</span>
                  <br />
                  <span class="name">{{ n.name }}</span>
                </div>

                <priceBox
                  :price="n.price"
                  :isPriceActive="config.price.active"
                  :priceLayout="config.price.layout"
                />

                <highlightsBox
                  :isHighlightsActive="config.product_highlights.active"
                  :highlights="n.highlights"
                  :highlightsCount="config.product_highlights.highlight_count"
                />
              </div>
            </a>
          </div>
        </div>
        <div
          class="rec_right"
          :class="
            !scrollView.right
              ? 'visibility_hidden'
              : config.widget_settings.scroll_arrows
              ? 'white_bg_arrow'
              : 'transparent_bg_right_arrow'
          "
          id="right-button"
          @click="swipeRight"
        >
          <img
            alt="arrow_right"
            class="arrow right-arrow"
            src="../../../../src/assets/images/arrow.svg"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import iconAndTitle from "../../../../src/components/iconAndTitle.vue";
import imageAndOffer from "../../../../src/components/imageAndOffer.vue";
import labelInvertedHorizontal from "../../../../src/components/labelInvertedHorizontal.vue";
import labelInvertedVertical from "../../../../src/components/labelInvertedVertical.vue";
import labelHorizontal from "../../../../src/components/labelHorizontal.vue";
import priceBox from "../../../../src/components/priceBox.vue";
import highlightsBox from "../../../../src/components/highlightsBox.vue";
import { Utility } from "fdk-client-javascript/common";

export default {
  props: {
    id: String,
  },
  data() {
    return {
      config: Object,
      isMounted: false,
      product: { items: [] },
      follow: {},
      review_map: {},
      utm: {
        source: "recommendation",
        medium: "",
      },
      scrollView: {
        left: false,
        right: true,
      },
      DEFAULT_IMG: `https://hdn-1.fynd.com/company/884/applications/000000000000000000000001/theme/pictures/free/original/theme-image-1598448644345.png`,
    };
  },
  components: {
    iconAndTitle,
    imageAndOffer,
    priceBox,
    highlightsBox,
    labelInvertedHorizontal,
    labelInvertedVertical,
    labelHorizontal,
  },
  name: "productPreview",
  async mounted() {
    let url = window.location.href;
    const pageDetail = Utility.convertUrlToAction(url);
    const recomm_slug = this.id;
    await this.getData(recomm_slug, pageDetail);
    this.isMounted = true;
    window.FPI.event.on("wishlist.add", this.wishAdd);
    window.FPI.event.on("wishlist.remove", this.wishRemove);
    this.utm.medium = recomm_slug;
  },
  methods: {
    wishit(event, citem, productData, accountsData) {
      console.log("Follow = ", this.follow);
      if (accountsData.is_logged_in) {
        citem.follow = this.follow[citem.slug];
        productData.updateWishList(event, citem);
      } else {
        accountsData.openLogin();
      }
    },
    wishAdd(data) {
      this.follow[data.slug] = true;
    },
    wishRemove(data) {
      this.follow[data.slug] = false;
    },
    getData(recomm_slug, pageDetail) {
      var url = window.location.origin;
      console.log("Url = ", url);
      axios
        .get(
          `${url}/ext/recalpha/api/platform/v1.0/recommendations/${recomm_slug}/pages/${pageDetail.page.type}/config?only-active=true`
        )
        .then((response) => {
          this.config = response.data;
          this.getProduct(recomm_slug, pageDetail);
        })
        .catch((error) => {
          console.error("Fetch Config Error ::: ", error);
        });
    },
    getProduct(recomm_slug, pageDetail) {
      var url = window.location.origin;

      var params = {};
      var cartItemList = [];
      if (pageDetail.page.params && pageDetail.page.params.slug) {
        params.item_slug = pageDetail.page.params.slug[0];
      }
      if (pageDetail?.page?.type === "cart") {
        const cartItem = window?.FPI?.state?.cart?._data?.all_cart_data?.items;
        params.item_slug = cartItem?.[0]?.product?.slug;
        for (let i = 0; i < cartItem?.length; i++) {
          cartItemList.push(cartItem?.[i]?.product?.slug);
        }
      }

      var apiUrl = `${url}/ext/recalpha/api/application/v1.0/recommend/${recomm_slug}`;
      if (pageDetail.page.params && pageDetail.page.params.slug) {
        params.item_slug = pageDetail.page.params.slug[0];
      }
      if (pageDetail?.page?.type === "cart") {
        const cartItem = window?.FPI?.state?.cart?._data?.all_cart_data?.items;
        params.item_slug = cartItem?.[0]?.product?.slug;
      }

      axios
        .post(apiUrl, {
          item_slug: cartItemList,
        })
        .then((response) => {
          this.product = response.data;
          var f = {};
          var id_to_slug = {}; // needed bcoz FPI wishlist event uses slug where as  state.products.wishlistIds.subscribe use ids
          var keymap = this.config.reviews.keymap; //review keymap
          var r = {};
          this.product.items.forEach(function (item) {
            //wishlist map
            f[item.slug] = false;
            id_to_slug[item.uid] = item.slug; //using id helps with FPI events

            //review data map
            var rmap = {
              ratings_total: 0,
              ratings_count: 0,
              reviews_count: 0,
            };
            item._custom_meta.forEach(function (meta_item) {
              if (keymap.ratings_total == meta_item.key) {
                rmap.ratings_total = parseInt(meta_item.value);
              } else if (keymap.ratings_count == meta_item.key) {
                rmap.ratings_count = parseInt(meta_item.value);
              } else if (keymap.reviews_count == meta_item.key) {
                rmap.reviews_count = parseInt(meta_item.value);
              }
            });
            rmap.rating = parseInt(rmap.ratings_total / rmap.ratings_count);
            r[item.slug] = rmap;
          });

          //Adds already wishlisted items to map
          window.FPI.state.products.wishlistIds.subscribe((items) => {
            items.forEach((item) => {
              if (id_to_slug[item] !== undefined) {
                f[id_to_slug[item]] = true;
              }
            });
          });
          this.follow = f;
          this.review_map = r;
        })
        .catch((error) => {
          console.log("product api error", error);
        });
    },
    getColor(product, index) {
      const rating = this.review_map[product.slug].rating;
      if (rating && index <= rating) {
        return this.config.reviews.star_color;
      }
      return "#E6E6E6";
    },
    scrollTo(element, scrollPixels, duration) {
      const scrollPos = element.scrollLeft;
      // Condition to check if scrolling is required
      if (
        !(
          (scrollPos === 0 || scrollPixels > 0) &&
          (element.clientWidth + scrollPos === element.scrollWidth ||
            scrollPixels < 0)
        )
      ) {
        // Get the start timestamp
        const startTime =
          "now" in window.performance
            ? performance.now()
            : new Date().getTime();
        // eslint-disable-next-line no-inner-declarations
        function scroll(timestamp) {
          const timeElapsed = timestamp - startTime;
          const progress = Math.min(timeElapsed / duration, 1);
          element.scrollLeft = scrollPos + scrollPixels * progress;
          if (timeElapsed < duration) {
            window.requestAnimationFrame(scroll);
          } else {
            return 0;
          }
        }

        window.requestAnimationFrame(scroll);
      }
    },
    swipeLeft() {
      const parentDivWidth =
        document?.getElementsByClassName("card-layout-1")?.[0]?.clientWidth +
        15;
      let scrollWidth = parentDivWidth > 300 ? -300 : -parentDivWidth;
      const content = this.$refs.content;
      this.scrollTo(content, scrollWidth, 800);
      this.scrollViewUpdate(scrollWidth);
    },
    swipeRight() {
      const parentDivWidth =
        document?.getElementsByClassName("card-layout-1")?.[0]?.clientWidth +
        15;
      let scrollWidth = parentDivWidth > 300 ? 300 : parentDivWidth;
      const content = this.$refs.content;
      this.scrollTo(content, scrollWidth, 800);
      this.scrollViewUpdate(scrollWidth);
    },
    scrollViewUpdate(scrollPixels) {
      const content = this.$refs.content;
      this.scrollView.right =
        content.clientWidth +
          content.scrollLeft +
          scrollPixels -
          content.scrollWidth +
          0.5 <
        0;
      this.scrollView.left = content.scrollLeft + scrollPixels > 0;
    },
  },
  computed: {
    cssProps() {
      return {
        "--wishiconColor": this.config.wishlist.color,
        "--discountColor": this.config.discount.text_color,
        "--imageHeight": this.config.product_image.height + "px",
        "--discountSize": this.config.discount.text_size + "px",
        "--discountbgColor": this.config.discount.background_color,
        "--namebrandColor": this.config.product_title.text_color,
        "--nameproductColor": this.config.product_title.secondary_text_color,
        "--namebrandSize": this.config.product_title.font_size + "px",
        "--nameWidth": this.config.product_title.text_limit + "ch",
        "--brandFontWeight": this.config.product_title.highlight_brand_name
          ? 600
          : 400,
        "--breakWhiteSpace": this.config.product_title.multiline_product_name
          ? "nowrap"
          : "break-spaces",
        "--overflowVisible": this.config.product_title.multiline_product_name
          ? "hidden"
          : "visible",
        "--primaryPriceColor": this.config.price.text_color,
        "--secondaryPriceColor": this.config.price.secondary_text_color,
        "--pricefontSize": this.config.price.text_size + "px",
        "--addtocartFont": this.config.cart_button.font_size + "px",
        "--addtocartlabelColor": this.config.cart_button.label_color,
        "--addtocartbgColor": this.config.cart_button.background_color,
        "--titleColor": this.config.widget_settings.title_color,
        "--subtitleColor": this.config.widget_settings.subtitle_color,
        "--cardBorderRadius":
          this.config.product_card_style.border_radius + "px",
        "--cardBackgroundColor":
          this.config.product_card_style.background_color,
        "--cardBorderColor":
          this.config.new_config.product_card_style.border_colour,
        "--cardPadding": this.config.product_card_style.padding + "px",
        "--highlightfontSize": this.config.product_highlights.font_size + "px",
        "--highlightMarginTop":
          this.config.product_highlights.margin_top + "px",
        "--highlightBorderBottom": this.config.product_highlights.border_bottom
          ? "1px"
          : "0px",
        "--highlightColor": this.config.product_highlights.font_color,
        "--cardBoxShadow": this.config.product_card_style.box_shadow
          ? "rgba(99, 99, 99, 0.5) 0px 2px 8px 0px"
          : "none",
        "--leftArrowVisibility":
          this.config.widget_settings.max_products > 2 ? "inherit" : "hidden",
        "--rightArrowVisibility":
          this.config.widget_settings.max_products > 2 ? "inherit" : "hidden",
        "--titleAlignment": this.config.new_config.widget_settings
          .title_alignment
          ? this.config.new_config.widget_settings.title_alignment
          : "left",
        "--subtitleAlignment": this.config.new_config.widget_settings
          .sub_title_alignment
          ? this.config.new_config.widget_settings.sub_title_alignment
          : "left",
        "--titleFontSize": this.config.new_config.widget_settings
          .title_font_settings.font_size
          ? `${this.config.new_config.widget_settings.title_font_settings.font_size}px`
          : `12px`,
        "--subtitleFontSize": this.config.new_config.widget_settings
          .sub_title_font_settings.font_size
          ? `${this.config.new_config.widget_settings.sub_title_font_settings.font_size}px`
          : `12px`,
        "--titleFontWeight": this.config.new_config.widget_settings
          .title_font_settings.font_weight
          ? `${this.config.new_config.widget_settings.title_font_settings.font_weight}`
          : 600,
        "--subtitleFontWeight": this.config.new_config.widget_settings
          .sub_title_font_settings.font_weight
          ? `${this.config.new_config.widget_settings.sub_title_font_settings.font_weight}`
          : 500,
        "--reviewFontSize": this.config.new_config.reviews.review_font_size
          ? `${this.config.new_config.reviews.review_font_size}px`
          : `12px`,
        "--reviewFontColor": this.config.new_config.reviews.review_font_colour,
      };
    },
  },
};
</script>
<style lang="less" scoped>
@import "../less/appbindings.less";
</style>
