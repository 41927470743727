<template>
  <App id="bought-together"/>
</template>

<script>
import App from './App.vue'
export default {
  name: 'by-brand', // why all components have same name?
  components: {
      App
  }
}
</script>